import React, { FormEvent, useEffect, useMemo, useRef, useState } from 'react';

import Text from '@components/common/Text/Text';

import css from './InputField.module.scss';

type InputFieldProps = {
    id: string;
    name: string;
    type: string;
    label: string;
    placeholder?: string;
    disabled: boolean;
    isFloatingLabel?: boolean;
    error: boolean;
    valid: boolean;
    autoFocus?: boolean;
    value: string | number;
    icon?: string;
    height?: string;
    iconPosition?: 'left' | 'right';
    onChange: React.EventHandler<any>;
    onHandleChange: (value) => void;
    onKeyPress: (value) => void;
    onBlur?: React.EventHandler<any>;
    onHandleBlur?: (value) => void;
    customStyle?: string;
    customCssStyle?: any;
    customLabelStyle?: string;
    isActivatedLabel?: boolean;
    iconRef?: React.RefObject<HTMLInputElement>;
    isAmount?: boolean;
    padding?: string;
    inputMaxLength?: number;
    inputAutoComplete?: boolean;
};

const InputField = ({
    id,
    name,
    type,
    label,
    placeholder,
    value,
    disabled,
    error,
    valid,
    icon,
    iconPosition,
    onChange,
    onBlur,
    onHandleChange,
    onHandleBlur,
    customStyle,
    customCssStyle,
    iconRef,
    height,
    isAmount,
    padding,
    inputMaxLength,
    inputAutoComplete,
    customLabelStyle,
    isFloatingLabel,
    autoFocus,
    isActivatedLabel,
}: InputFieldProps) => {
    const [isInputFocused, setInputFocused] = useState<boolean>(false);
    const [inputValue, setInputValue] = useState<any>(value);
    const inputRef = useRef(null);

    const getClassName = () => {
        if (disabled && !value && placeholder) {
            value = placeholder;
        }
        let className = css.input;
        if (valid) {
            className += ' ' + css.input__valid;
        }
        if (isFloatingLabel && !disabled && (!isInputFocused || value)) {
            className += ' ' + css.input__new_style;
        }
        if (isFloatingLabel && !disabled && (isInputFocused || isActivatedLabel) && !value) {
            className += ' ' + css.input__new_style__focused;
        }
        if (value && !disabled) {
            className += ' ' + css.input__new_style__active;
        }
        if (valid && disabled) {
            className += ' ' + css.input__valid__disabled;
        }
        if (error) {
            className += ' ' + css.input__error;
        }

        if (error && disabled) {
            className += ' ' + css.input__error__disabled;
        }
        if (icon) {
            if (iconPosition === 'right') {
                className += ' ' + css.input__iconRight;
            } else {
                className += ' ' + css.input__iconLeft;
            }
        }

        if (disabled) {
            className += ' ' + css.input__disabled;
        }
        if (isAmount) {
            className += ' ' + css.input__isAmount;
        }

        if (customStyle) {
            className += ' ' + css[`input__${customStyle}`];
            if (valid) {
                className += ' ' + css[`input__${customStyle}__valid`];
            }
            if (error) {
                className += ' ' + css[`input__${customStyle}__error`];
            }
        }
        return className;
    };

    const getValue = (value) => {
        if (isAmount && typeof value === 'string') {
            return value.replace(/,/g, '.');
        }
        return value;
    };

    useEffect(() => {
        setTimeout(() => {
            // @ts-ignore
            id && setInputValue(document.getElementById(id).value);
        }, 2000);
    }, []);

    const getLabelStyle = useMemo(() => {
        let style = customLabelStyle || '';
        style += ' ' + isFloatingLabel ? css.label__fieldset : css.label;
        if (isFloatingLabel && (isInputFocused || inputValue || isActivatedLabel)) {
            style += ' ' + css.label__fieldset__active;
        }
        return style;
    }, [inputValue, isFloatingLabel, isInputFocused]);

    useEffect(() => {
        if (inputRef?.current?.value) {
            // @ts-ignore
            setInputValue(inputRef?.current?.value);
        }
    }, [inputRef]);

    useEffect(() => {
        !inputValue && setInputValue(value);
    }, [value]);

    return (
        <div className={css.wrapper}>
            {label && (
                <label
                    onClick={() => inputRef.current && inputRef.current.focus()}
                    htmlFor={id}
                    className={getLabelStyle}
                >
                    <Text variant={'caption_01'} color={'txt-primary'} customStyle={customCssStyle}>
                        <span>{label}</span>
                    </Text>
                </label>
            )}
            <div className={css.input__wrapper}>
                {type === 'textarea' ? (
                    <textarea
                        ref={inputRef}
                        name={name}
                        id={id}
                        placeholder={placeholder}
                        className={`${getClassName()} ${css.text_area}`}
                        value={value}
                        onChange={onChange}
                        onBlur={(e) => {
                            onBlur(e);
                            setInputFocused(false);
                            onHandleBlur && onHandleBlur(e);
                        }}
                        onInput={(e: FormEvent) => {
                            setInputValue((e.target as HTMLInputElement).value);
                        }}
                        onFocus={() => setInputFocused(true)}
                        disabled={disabled}
                        style={{ height: height || '120px', padding: padding || '' }}
                        autoFocus={autoFocus}
                    />
                ) : (
                    <input
                        type={type}
                        name={name}
                        id={id}
                        ref={inputRef}
                        placeholder={placeholder}
                        className={getClassName()}
                        value={getValue(value)}
                        onChange={(e) => {
                            onChange(e);
                            onHandleChange && onHandleChange(e);
                        }}
                        onInput={(e: FormEvent) => {
                            setInputValue((e.target as HTMLInputElement).value);
                        }}
                        onBlur={(e) => {
                            onBlur(e);
                            setInputFocused(false);
                            onHandleBlur && onHandleBlur(e);
                        }}
                        autoFocus={autoFocus}
                        onFocus={() => setInputFocused(true)}
                        disabled={disabled}
                        maxLength={inputMaxLength}
                        autoComplete={inputAutoComplete ? 'on' : 'off'}
                        style={customCssStyle || null}
                    />
                )}
                {icon && (
                    <div
                        ref={iconRef}
                        className={`${css.input__icon} ${
                            iconPosition === 'right' ? css[`input__icon__${iconPosition}`] : css.input__icon__left
                        }`}
                    >
                        <img src={icon} alt="input icon" />
                    </div>
                )}
            </div>
        </div>
    );
};

export default InputField;
